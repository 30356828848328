/* eslint-disable @typescript-eslint/no-use-before-define */
export type FieldNumberValue = number;

export type FieldNumberValueInput = string | FieldNumberValue;

/**
 * Convert field number value input to the value
 * that should be puts in the taxonomy template output.
 */
export function formatFieldNumberValue(options: {
  /** Accepts `TaxonomyFieldNumber` */
  field: { type: 'number' };
  input: FieldNumberValueInput | undefined;
}): string {
  const { input } = options;

  const number = asNumber(input);
  if (number === undefined) return '';

  // returning original input value preserves leading zeros in numbers
  return input?.toString() ?? '';
}

/**
 * Convert field number value input to the value
 * structured output object.
 */
export function decodeFieldNumberValue(options: {
  /** Accepts `TaxonomyFieldNumber` */
  field: { type: 'number' };
  input: FieldNumberValueInput | undefined;
}): FieldNumberValue | undefined {
  const { input } = options;

  return asNumber(input);
}

function asNumber(
  input: FieldNumberValueInput | undefined,
): number | undefined {
  if (input === undefined) return undefined;
  else if (typeof input === 'string') {
    const value = parseFloat(input);

    return isNaN(value) ? undefined : value;
  } else if (typeof input === 'number') {
    return input;
  }

  return undefined;
}
