import {
  type Guideline,
  type GuidelineTypes,
  isTaxonomyGuideline,
} from '@grasp-gg/lib-core-guidelines';

export default (guidelines: Guideline[]) =>
  guidelines.filter((guideline) => {
    if (isTaxonomyGuideline(guideline)) {
      return true;
    }

    if (
      (
        [
          'MaxBudget',
          'MaxDailyBudget',
          'BidValue',
        ] satisfies GuidelineTypes[] as GuidelineTypes[]
      ).includes(guideline.type as GuidelineTypes)
    ) {
      return true;
    }

    return false;
  });
